<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card" style="padding:5%;">
          <div class="card-header">
            <h3 class="card-title">CVS Campaign Data</h3><br/><br/>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Data Type</th>
                  <th scope="col">Past Week: 2/25/24 - 3/4/24</th>
                  <th scope="col">Total: 1/24/24 - 3/4/24</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td> # Activities Completed</td>
                  <td> {{ brandStats.activities.last_week }} </td>
                  <td> {{ brandStats.activities.project  }} </td>
                </tr>
                <tr>
                  <td># Activities With 1+ Items OOS</td>
                  <td> {{ brandStats.oos.last_week }}</td>
                  <td> {{ brandStats.oos.project + 6 }} </td>
                </tr>
                <tr>
                  <td> # Repeat Locations</td>
                  <td> {{ brandStats.multiple_location.last_week }} </td>
                  <td> {{ brandStats.multiple_location.project }} </td>
                </tr>
                <tr v-for="(item, index) in brandStats.items" :key="item.item">
                  <td> Item {{ index + 1}}: # {{ item.item }} Purchased</td>
                  <td>{{ item.last_week }}</td>
                  <td>{{ item.project }}</td>
                </tr>
                <tr>
                  <td> Buy-Me $ Credits Consumed</td>
                  <td> ${{ brandStats.reimbursement_credits.last_week }} </td>
                  <td> ${{ brandStats.reimbursement_credits.project }} </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {

  data () {
    return {
      brandStats: {
        activities: {
          last_week: 0,
          project: 0
        },
        multiple_location: {
          last_week: 0,
          project: 0
        },
        oos: {
          last_week: 0,
          project: 0
        },
        items: [],
        reimbursement_credits: {
          last_week: 0,
          project: 0
        }
      }
    }
  },
  methods: {
    getBrandStats () {
      let brandId = 0
      if (this.$store.state.userData.role === 'super-admin') {
        brandId = this.$route.params.brandId
      } else {
        brandId = this.$store.state.userData.brandId
      }
      this.$store.dispatch('getBrandStats', brandId).then((response) => {
        console.log(response.response.body)
        this.brandStats = response.response.body
        console.log(this.brandStats)
      })
    }
  },
  mounted () {
    this.getBrandStats()
  }
}
</script>
<style scoped>
</style>
