var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("div", { staticClass: "card", staticStyle: { padding: "5%" } }, [
          _c("div", { staticClass: "card-header" }, [
            _c("h3", { staticClass: "card-title" }, [
              _vm._v("CVS Campaign Data")
            ]),
            _c("br"),
            _c("br"),
            _c("table", { staticClass: "table" }, [
              _vm._m(0),
              _c(
                "tbody",
                [
                  _c("tr", [
                    _c("td", [_vm._v(" # Activities Completed")]),
                    _c("td", [
                      _vm._v(
                        " " + _vm._s(_vm.brandStats.activities.last_week) + " "
                      )
                    ]),
                    _c("td", [
                      _vm._v(
                        " " + _vm._s(_vm.brandStats.activities.project) + " "
                      )
                    ])
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("# Activities With 1+ Items OOS")]),
                    _c("td", [
                      _vm._v(" " + _vm._s(_vm.brandStats.oos.last_week))
                    ]),
                    _c("td", [
                      _vm._v(" " + _vm._s(_vm.brandStats.oos.project + 6) + " ")
                    ])
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v(" # Repeat Locations")]),
                    _c("td", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.brandStats.multiple_location.last_week) +
                          " "
                      )
                    ]),
                    _c("td", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.brandStats.multiple_location.project) +
                          " "
                      )
                    ])
                  ]),
                  _vm._l(_vm.brandStats.items, function(item, index) {
                    return _c("tr", { key: item.item }, [
                      _c("td", [
                        _vm._v(
                          " Item " +
                            _vm._s(index + 1) +
                            ": # " +
                            _vm._s(item.item) +
                            " Purchased"
                        )
                      ]),
                      _c("td", [_vm._v(_vm._s(item.last_week))]),
                      _c("td", [_vm._v(_vm._s(item.project))])
                    ])
                  }),
                  _c("tr", [
                    _c("td", [_vm._v(" Buy-Me $ Credits Consumed")]),
                    _c("td", [
                      _vm._v(
                        " $" +
                          _vm._s(
                            _vm.brandStats.reimbursement_credits.last_week
                          ) +
                          " "
                      )
                    ]),
                    _c("td", [
                      _vm._v(
                        " $" +
                          _vm._s(_vm.brandStats.reimbursement_credits.project) +
                          " "
                      )
                    ])
                  ])
                ],
                2
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Data Type")]),
        _c("th", { attrs: { scope: "col" } }, [
          _vm._v("Past Week: 2/25/24 - 3/4/24")
        ]),
        _c("th", { attrs: { scope: "col" } }, [
          _vm._v("Total: 1/24/24 - 3/4/24")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }